import { Img } from "components";

const InfluencerFeaturesSection = () => {
  const socialFeedList = [
    {
      postTitle: "Choose your Category",
      postDescription:
        "Discover a variety of trending categories and choose your favorites to watch",
    },
    {
      postTitle: "Live Matches",
      postDescription:
        "Compete against others and vote in live stream matches with real-time audience engagement",
    },
    {
      postTitle: "Prompt Matches",
      postDescription:
        "Select a category, record your challenge, and submit your post. Receive results within 24 hours",
    },
  ];

  const featureUnlockList = [
    {
      headingText: "Unlock Features",
      descriptionText: "Gain access to exclusive features...",
    },
    {
      headingText: "Connect",
      descriptionText:
        "Discover new influencers and watch them rise to the top of the leaderboards on the basis of their talent - not an algorithm.",
    },
    {
      headingText: "Post",
      descriptionText:
        "Post your photos and videos, gain likes, and grow a following",
    },
  ];

  return (
    <div
      className="w-full bg-no-repeat"
      id="incfluencer"
      style={{
        background: "url(/images/img_more_features.png)",
        backgroundSize: "cover",
        backgroundPosition: "50% 30%",
      }}
    >
      <div className="lg:w-10/12 xl:w-10/12 md:w-11/12 container px-[15px] sm:px-0 md:px-0 lg:px-0 mx-auto py-[45px] sm:py-[60px] md:py-[60px] lg:py-[100px] xl:py-[100px]">
        {/*<h2 className="text-center pb-[10px] sm:pb-[10px] md:pb-[10px] lg:pb-[45px] xl:pb-[45px] text-[20px] sm:text-[24px] md:text-[30px] lg:text-[44px] xl:text-[44px] text-[#314670] font-bold influencer">*/}
        {/*  Become an Influencer*/}
        {/*</h2>*/}
        <div className="flex flex-col lg:flex-row xl:flex-row justify-center items-center py-4">
          {/* Content section */}
          <div className="relative z-10 flex flex-col xl:flex-row lg:flex-row md:flex-col md:justify-between md:gap-x-4">
            <div className="md:relative lg:absolute xl:absolute xl:top-28 lg:top-28 md:top-0 xl:-left-[10rem] lg:-left-[10rem] md:left-0 xl:w-[17rem] lg:w-[17rem] md:w-full xl:h-full lg:h-full md:h-auto flex xl:flex-col lg:flex-col md:flex-row flex-col gap-y-3 social-feed">
              {socialFeedList.map((item) => (
                <div
                  key={item.id}
                  className="md:text-left lg:text-right xl:text-right flex flex-col md:items-start xl:items-end lg:items-end gap-y-3 md:w-[33.33%] xl:w-full lg:w-full xl:my-[30px] lg:my-[30px] md:my-[10px]"
                >
                  <div className="w-2 h-2 bg-[#ffa801] rounded-full"></div>
                  <h4 className="text-[#142C5C] font-bold text-[20px]">
                    {item.postTitle}
                  </h4>
                  <p className="text-[#142C5C75] text-[16px]">
                    {item.postDescription}
                  </p>
                </div>
              ))}
            </div>

            <div className="md:relative lg:absolute xl:absolute xl:top-28 lg:top-28 md:top-0 xl:-right-[10rem] lg:-right-[10rem] md:right-0 xl:w-[17rem] lg:w-[17rem] md:w-full xl:h-full lg:h-full md:h-auto xl:flex lg:flex md:hidden hidden flex-col gap-y-3">
              {featureUnlockList.map((item) => (
                <div
                  key={item.id}
                  className="text-left flex flex-col items-start gap-y-3 my-[30px] feature-unlock"
                >
                  <div className="w-2 h-2 bg-[#ffa801] rounded-full"></div>
                  <h4 className="text-[#142C5C] font-bold text-[20px]">
                    {item.headingText}
                  </h4>
                  <p className="text-[#142C5C75] text-[16px]">
                    {item.descriptionText}
                  </p>
                </div>
              ))}
            </div>

            {/* Desktop image */}
            <div className="md:flex justify-center items-center w-full xl:my-0 lg:my-0 md:my-[15px] mb-[15px] pt-[30px] sm:pt-0 md:pt-0 lg:pt-0 xl:pt-0">
              <Img
                src="/images/bcome and influncer v2.png"
                className="w-full lg:max-w-[745px] xl:max-w-[745px] md:max-w-[250px]"
              />
            </div>
            <div className="md:relative lg:absolute xl:absolute xl:top-28 lg:top-28 md:top-0 xl:-right-[10rem] lg:-right-[10rem] md:right-0 xl:w-[17rem] lg:w-[17rem] md:w-full xl:h-full lg:h-full md:h-auto xl:hidden lg:hidden md:flex xl:flex-col lg:flex-col md:flex-row gap-y-3">
              {featureUnlockList.map((item) => (
                <div
                  key={item.id}
                  className="text-left flex flex-col items-start md:w-[33.33%] xl:w-full lg:w-full gap-y-3 xl:my-[30px] lg:my-[30px] md:my-[10px] feature-unlock"
                >
                  <div className="w-2 h-2 bg-[#ffa801] rounded-full"></div>
                  <h4 className="text-[#142C5C] font-bold text-[20px]">
                    {item.headingText}
                  </h4>
                  <p className="text-[#142C5C75] text-[16px]">
                    {item.descriptionText}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfluencerFeaturesSection;
