import { Button, Heading, Img } from "./..";
import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { IoClose } from "react-icons/io5";


export default function Header({ ...props }) {
  const [toggle, setToggle] = useState(false);

  const handleView = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <header
    {...props}
    className={`${props.className} sm:py-[26px] md:py-[26px] lg:py-[26px] py-[15px] px-3 sm:px-3 md:px-0 lg:px-0 xl:px-0 transition-custom z-[99999] w-full fixed top-0 left-0`}
    style={{ backgroundColor: 'rgba(211, 211, 211, 0.15)' }} 
  >
      <div className="header-container container flex items-center justify-between w-full sm:w-11/12 md:w-11/12  lg:w-10/12  xl:w-10/12 mx-auto">
        <div className="flex items-center">
        {/*  <Img*/}
        {/*  src="images/pagent-logo-icon.png"*/}
        {/*  alt="Header Logo"*/}
        {/*  className="h-[40px] sm:h-[40px] md:h-[40px] lg:h-[50px] xl:h-[50px] w-[40px] sm:w-[40px] md:w-[40px] lg:w-[50px] xl:w-[50px] object-cover"*/}
        {/*/>*/}
          <Img
          src="images/pagent-logo-text.png"
          alt="Header Logo"
          className="h-[30px] sm:h-[40px] md:h-[40px] lg:h-[50px] xl:h-[50px] w-auto object-cover relative"
        />
        </div>
        <div className="md:flex justify-center items-center gap-x-5 mobile-device">
          <ul className="flex gap-x-5 items-center">
            <li className="relative">
              <a href="/">
                <Heading
                  size="headingxs"
                  as="h6"
                  style={{
                    fontFamily: 'Avenir Next, sans-serif',
                    fontWeight: 700,
                    fontSize: '16px',
                  }}
                  className="mr-4"
                >
                  Home
                </Heading>
              </a>
            </li>
            <li className="relative">
              <button onClick={() => handleView("about")} className="mr-4">
                <Heading
                  size="headingxs"
                  as="h6"
                  style={{
                    fontFamily: 'Avenir Next, sans-serif',
                    width: '83px',
                    height: '22px',
                  }}
                  className="mr-4"
                >
                  About Us
                </Heading>
              </button>
            </li>
            <li className="relative">
              <button onClick={() => handleView("incfluencer")} className="mr-4">
                <Heading
                  size="headingxs"
                  as="h6"
                  style={{
                    fontFamily: 'Avenir Next, sans-serif',
                    width: '72px',
                    height: '22px',
                  }}
                  className="mr-4"
                >
                  Features
                </Heading>
              </button>
            </li>
            <li className="relative">
              <Button
                size="sm"
                variant="gradient"
                shape="round"
                color="yellow_linear_orange_600"
                className="min-w-[120px] h-[48px] font-bold"
              >
                Get the App
              </Button>
            </li>
          </ul>
        </div>
        <div className="relative md:hidden">
          <button onClick={() => setToggle(!toggle)}>
            {toggle ? <IoClose size={25} color="#fff" /> : <FaBars size={25} color="#fff" />}
          </button>
          {toggle && (
            <div className="absolute -left-32 top-1 bg-white-0/75 w-32 rounded-lg">
              <ul className="flex flex-col gap-y-3 justify-center items-center py-4">
                <li className="relative">
                  <a href="/">
                    <Heading size="headingxs" as="h6" className="text-black">
                      Home
                    </Heading>
                  </a>
                </li>
                <li className="relative">
                  <a href="#about">
                    <Heading size="headingxs" as="h6" className="text-black">
                      About Us
                    </Heading>
                  </a>
                </li>
                <li className="relative">
                  <a href="#incfluencer">
                    <Heading size="headingxs" as="h6" className="text-black">
                      Features
                    </Heading>
                  </a>
                </li>
                <li className="relative">
                  <Button
                    size="sm"
                    variant="gradient"
                    shape="round"
                    color="yellow_linear_orange_600"
                    className="text-sm  w-full"
                  >
                    Get the App
                  </Button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}



