import { Helmet } from "react-helmet";

import React, { useEffect, useState } from "react";
import Footer from "components/Footer";
import InfluencerFeaturesSection from "./InfluencerFeaturesSection";
import FeaturesSection from "./FeaturesSection";
import PagentisNotJustSocialMedia from "./PagentisNotJustSocialMedia";
import Aboutus from "./Aboutus";
import TheFirstKind from "./TheFirstKind";
import Header from "../../components/Header";

export default function WebDesignPage() {
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) { // Adjust the value to your needs
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  let className = scrolled ? "scrolled" : "";

  return (<>
      <Helmet>
        <title> Pagent</title>
        <meta
          name="description"
          content="Join Pagent, the ultimate social gaming app. Compete in challenges, connect with new people, and showcase your talents. Climb the leaderboard and become an influencer in categories like Karaoke, Dance, Makeup, and more."
        />
        <link rel="icon" type="image/x-icon" href="/images/pagent-logo-icon.png"/>
      </Helmet>
      <>
        <Header className={className} />
        <FeaturesSection />
        <PagentisNotJustSocialMedia />
        <Aboutus />
      </>
      <InfluencerFeaturesSection />

      <TheFirstKind />
      <Footer />
    </>);
}
