import { Link } from "react-router-dom";
import { Button, Heading, Img, Text } from "./..";

export default function Footer({ ...props }) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });
  };

  return (
    <footer
      {...props}
      className={`${props.className} flex justify-center items-center pt-[20px] pb-[50px] sm:pt-[30px] md:pt-[45px] lg:pt-[50px] sm:pb-[30px] md:pb-[45px] lg:pb-[50px] bg-gray-900 relative`}
    >
      {/* Scroll to top button */}
      <div
        className="fixed right-[15px] sm:right-[30px] md:right-[30px] lg:right-[30px] bottom-[15px] sm:bottom-[50px] md:bottom-[50px] lg:bottom-[50px] xl:bottom-[50px] cursor-pointer"
        onClick={scrollToTop}
      >
        <img
          src="/images/arrowtop.png"
          alt="top"
          className="w-[40px] h-[40px]"
        />
      </div>

      <div className="container lg:w-10/12 xl:w-10/12 md:w-11/12 mx-auto px-[15px] flex justify-center md:px-5">
        <div className="flex w-full flex-col xl:gap-[50px] lg:gap-[50px] md:gap-[30px]">
          <div className="grid sm:grid-cols-2 grid-cols-1 p-6 md:p-0 gap-y-4">
            <div>
              <div className="flex items-center">
                <Img
                  src="images/pagent-logo-text.png"
                  alt="Header Logo"
                  className="h-[40px] sm:h-[40px] md:h-[40px] lg:h-[50px] xl:h-[50px] w-auto object-cover relative"
                />
              </div>
              {/*<Text as="p" className="leading-[150%] !text-white-a700_99">*/}
              {/*  <>*/}
              {/*    We aim to seamless social media app <br />*/}
              {/*    for your use*/}
              {/*  </>*/}
              {/*</Text>*/}
            </div>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-y-4">
              <ul className="flex flex-col items-start gap-5 self-center">
                <li>
                  <a href="/">
                    <Text as="p" className="!font-medium !text-gray-300_01">
                      Home
                    </Text>
                  </a>
                </li>
                <li>
                  <a href="#about-us-section">
                    <Text as="p" className="!font-medium !text-gray-300_01">
                      About Us
                    </Text>
                  </a>
                </li>
                <li>
                  <a href="#features-section">
                    <Text as="p" className="!font-medium !text-gray-300_01">
                      App Features
                    </Text>
                  </a>
                </li>
                <li>
                  <Link to="/termsconditions">
                    <Text as="p" className="!font-medium !text-gray-300_01">
                      Terms & Conditions
                    </Text>
                  </Link>
                </li>
                <li>
                  <Link to="/privacypolicy">
                    <Text as="p" className="!font-medium !text-gray-300_01">
                      Privacy Policy
                    </Text>
                  </Link>
                </li>
              </ul>
              <div className="flex flex-col sm:w-[16%] w-full gap-5 md:w-full lg:ml-[2rem] md:ml-0">
                {/*<div className="flex items-center gap-2">*/}
                {/*  <Img src="images/img_phone.svg" alt="Phone Icon" className="h-[24px] w-[24px]" />*/}
                {/*  <Text as="p" className="!font-medium !text-gray-300_01">*/}
                {/*    416.228.2345 (3328)*/}
                {/*  </Text>*/}
                {/*</div>*/}
                {/*<div className="flex items-center gap-2">*/}
                {/*  <Img src="images/img_phone.svg" alt="Tollfree Icon" className="h-[24px] w-[24px]" />*/}
                {/*  <Text as="p" className="!font-medium !text-gray-300_01">*/}
                {/*    1.800.267.2272*/}
                {/*  </Text>*/}
                {/*</div>*/}
                <div className="flex items-center gap-2">
                  <Img
                    src="images/img_lock.svg"
                    alt="Email Icon"
                    className="h-[24px] w-[24px]"
                  />
                  <Text as="p" className="!font-medium !text-gray-300_01">
                    <a href="mailto:support@pagent.tv">support@pagent.tv</a>
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between gap-0 md:gap-5 sm:gap-5 lg:gap-5 rounded-[30px] bg-transparant-white-10 px-3 sm:px-6 md:px-6 lg:px-9 xl:px-9 py-3 sm:py-5 md:py-5 lg:py-6">
            <Heading size="textxs" as="p">
              Copyright ©2024 Pagent
            </Heading>
            <div className="flex gap-2.5">
              <Button shape="circle" className="w-[40px] !rounded-[20px]">
                <a
                  href="https://www.facebook.com/profile.php?id=61564847472494&mibextid=JRoKGi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img src="images/img_facebook.svg" alt="Facebook" />
                </a>
              </Button>
              <Button shape="circle" className="w-[40px] !rounded-[20px]">
                <a
                  href="https://www.linkedin.com/company/pagent-social-corp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img src="images/img_link.svg" alt="LinkedIn" />
                </a>
              </Button>
              <Button shape="circle" className="w-[40px] !rounded-[20px]">
                <a
                  href="https://www.instagram.com/pagent.tv/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img src="images/img_info.svg" alt="Instagram" />
                </a>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
