import { Img } from "components";
import React from "react";

const TheFirstKind = () => {
  return (
    <div className="w-full bg-black relative pt-[45px] sm:pt-[60px] md:pt-[65px] lg:pt-[130px] xl:pt-[130px] pb-[65px]">
      {/* Background elements */}
      <Img
        src="/images/yellow-vector.png"
        className="absolute left-[15px] sm:left-[37px] md:left-[37px] lg:left-[37px] -top-[17px] sm:-top-11 md:-top-11 lg:-top-11 w-8 xs:w-12 md:w-18 lg:w-24"
      />

      {/* Content container with padding */}
      <div className="grid md:grid-cols-2 grid-cols-1 lg:w-10/12 xl:w-10/12 container md:w-11/12 px-[15px] sm:px-0 md:px-0 lg:px-0 mx-auto h-full gap-y-4">
        {/* Image Section - Displayed first on mobile */}
        <div className="flex justify-center md:justify-end lg:justify-end xl:justify-end items-center md:order-2">
          <Img 
            src="/images/the_first_kind_v2.png" 
            className="md:w-10/12 w-3/4"  // Increased size on mobile devices
          />
        </div>

        {/* Text Content Section */}
        <div className="flex justify-center flex-col md:items-start items-center h-full text-white-0 ">
          <h2 className="first-kind font-bold text-[20px] sm:text-[24px] md:text-[30px] lg:text-[44px] xl:text-[44px] pt[2.5rem] pb-[24px] mb-0 pr-0 sm:pr-0 md:pr-0 lg:pr-[60px]">
            Join now to secure your spot as an influencer or audience member.
          </h2>
          {/*<p className="text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[20px] font-[400] text-[#646668] leading-[24px] sm:leading-[26px] md:leading-[30px] lg:leading-[38px] xl:leading-[38px] text-center mb-[35px] md:text-left last-section">*/}
          {/*  Pagent is the first social gaming platform created. <br className="hidden sm:hidden md:hidden lg:block" />*/}
          {/*  Join to become one of the first users to compete or <br className="hidden sm:hidden md:hidden lg:block" />*/}
          {/*  vote. Don't miss out - get notified!*/}
          {/*</p>*/}
          <button className="px-7 py-3 text-white bg-[#31343a] rounded-full text-xl w-[228px] h-[60px]">
            Coming Soon
          </button>
        </div>
      </div>
    </div>
  );
};

export default TheFirstKind;



