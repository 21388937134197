import { Helmet } from "react-helmet";
import { Heading } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import PrivacyPolicySection from "./PrivacyPolicySection";
import { Link } from "react-router-dom"; 
import React, { useEffect, useState } from "react";

export default function TermsConditionsPage() {
    const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) { // Adjust the value to your needs
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  let className = scrolled ? "scrolled" : "";
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Pagent Social Corp</title>
        <meta
          name="description"
          content="Read the latest Terms & Conditions for Pagent Social Corp. Understand how we protect your privacy, manage personal information, and your use of our social gaming website."
        />
      </Helmet>
      <div className="flex w-full flex-col gap-[50px] bg-gradient3">
        <Header className={className} />
        <div className="flex flex-col items-center gap-10 pt-32">
          <div className="container-xs flex flex-col items-start md:px-5">
          <Link to="/" className="no-underline">
              <Heading size="headingmd" as="h1" className="!font-medium ">
                <span className="text-white-a700_99">Home &gt;</span>
                <span className="text-white-0">&nbsp;</span>
                <span className="text-white-0 ">Privacy and Policy</span>
              </Heading>
            </Link>
          </div>
          <div className="flex flex-col gap-[100px] self-stretch md:gap-[75px] sm:gap-[50px]">
            {/* Privacy Policy Section */}
            <PrivacyPolicySection />
            <div>
              <Footer className="mt-2.5" />
            </div>
          </div>
        </div>
        {/* <div className="absolute bottom-[4%] right-[3%] m-auto h-[68px] w-[5%] rounded-[34px] bg-gradient" /> */}
      </div>
    </>
  );
}
