import {Img } from "components";
import React from "react";

const ChatMenu = () => {
  const menu = [
    {
      heading: "Karaoke",
      imageUrl: "/images/img_karaoke_1.png",
      width: "193px",
      height: "34px",
    },
    {
      heading: "Dance",
      imageUrl: "/images/img_mask_group.png",
      width: "193px",
      height: "34px",
    },
    {
      heading: "Makeup",
      imageUrl: "/images/img_makeup.png",
      width: "193px",
      height: "34px",
    },
    {
      heading: "Chat",
      imageUrl: "/images/img_chat_1.png",
      width: "193px",
      height: "34px",
    },
    {
      heading: "Fashion",
      imageUrl: "/images/img_fashion.png",
      width: "193px",
      height: "34px",
    },
    {
      heading: "Humor",
      imageUrl: "/images/img_humor.png",
      width: "193px",
      height: "34px",
    },
    {
      heading: "Food",
      imageUrl: "/images/img_food.png",
      width: "193px",
      height: "34px",
    },
  ];

  return (
    <div className="h-full w-full rounded-3xl bg-image flex flex-col px-4 pt-[30px] xl:pb-[30px] lg:pb-[58px] -ml-[1px]">
        <h3 className="chat-menu-title text-white-0 text-[24px] mb-3 font-[500] pt-[10px]">
          Categories Like
        </h3>
        <ul>
          {menu.map((item, index) => (
            <li 
              key={item.id}
              className={`chat-menu-item flex justify-start items-center mb-2 py-2 px-3 gap-3 ${index === 0 ? "border-[2px] border-[#5c4c00] rounded-2xl" : ""}`}
            >
              <Img src={item.imageUrl} alt={item.heading} className="w-6" />
              <a href="/#" className="text-white-0">{item.heading}</a>
            </li>
          ))}
        </ul>
    </div>
  );
};

export default ChatMenu;

