import React from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "pages/NotFound";
import WebDesign from "pages/WebDesign";
import PrivacyPolicy from "pages/TermsConditions";
import TermsConditions from "pages/PrivacyPolicy";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <WebDesign /> },
    { path: "*", element: <NotFound /> },
    // {
    //   path: "webdesign",
    //   element: <WebDesign />,
    // },
    {
      path: "termsconditions",
      element: <TermsConditions />,
    },
    {
      path: "privacypolicy",
      element: <PrivacyPolicy />,
    },
  ]);

  return element;
};

export default ProjectRoutes;
