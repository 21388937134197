import React from "react";
import PropTypes from "prop-types";

const shapes = {
  round: "rounded-[24px]",
  circle: "rounded-[50%]",
};
const variants = {
  fill: {
    white_A700_33: "bg-white-a700_33 shadow-sm text-white-0",
    white_0: "bg-white-0 shadow-xs text-blue_gray-800",
    transparant_white_10: "bg-transparant-white-10",
    blue_gray_900_01: "bg-blue_gray-900_01 text-white-0",
  },
  gradient: {
    yellow_linear_orange_600: "bg-gradient text-white-0",
  },
};
const sizes = {
  lg: "h-[68px] px-4",
  md: "h-[54px] pl-[34px] pr-[30px] text-[20px]",
  sm: "h-[48px] px-[18px] text-[16px]",
  xs: "h-[40px] px-2.5",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "xs",
  color = "transparant_white_10",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center text-center cursor-pointer whitespace-nowrap ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["round", "circle"]),
  size: PropTypes.oneOf(["lg", "md", "sm", "xs"]),
  variant: PropTypes.oneOf(["fill", "gradient"]),
  color: PropTypes.oneOf(["white_A700_33", "white_0", "blue_gray_900_01", "bg-blue_gray-900_01 text-white-0", "transparant_white_10", "yellow_linear_orange_600"]),
};

export { Button };
