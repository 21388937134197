import { Heading, Text } from "../../components";
export default function PrivacyPolicySection() {
  return (
    <>
      {/* privacy policy section */}
      <div className="flex flex-col items-center">
        <div className="container-xs md:px-5">
          <div className="flex flex-col items-center gap-5 rounded-[24px] bg-gray-900_01 p-8 sm:p-5">
            <div className="flex flex-col items-center gap-2.5 px-14 md:px-5">
              <a href="#" className="bg-gradient bg-clip-text">
                <Heading size="headingxl" as="h2" className="!text-transparent">
                  Privacy Policy
                </Heading>
              </a>
              <Heading size="16_medium" as="h3">
                <span className="text-white-0">Last updated:&nbsp;</span>
                <span className="text-greyscale-grey">July 26, 2024</span>
              </Heading>
            </div>
            <div className="flex w-full flex-col gap-5 py-3.5">
              <div className="flex flex-col items-start gap-1">
                <Heading className="mb-2" as="h4">
                  INTRODUCTION{" "}
                </Heading>
                <Text as="p" className="w-full leading-[27px]">
                  <>
                    Pagent Social Corp. and our affiliates and subsidiaries
                    (&quot;Company&quot; or &quot;We&quot;) respect your privacy
                    and are committed to protecting it by complying with this
                    policy. <br />
                    This policy describes: <br />
                    How we collect, use, disclose, and protect the personal
                    information of our customers and website users
                    (&quot;you&quot;). <br />
                    Describes the types of information we may collect from you
                    or that you may provide when you visit the website Pagent.tv
                    (our &quot;Website&quot;). <br />
                    Our practices for collecting, using, maintaining,
                    protecting, and disclosing that information. <br />
                    <br />
                    Privacy laws in Canada generally define &quot;personal
                    information&quot; as any information about an identifiable
                    individual, which includes information that can be used on
                    its own or with other information to identify, contact, or
                    locate a single person. Personal information does not
                    include business contact information, including your name,
                    title, or business contact information. <br />
                    This policy applies to information we collect, use, or
                    disclose about you: <br />
                    On this Website. <br />
                    In email, text, and other electronic messages between you
                    and this Website. <br />
                    Through mobile and desktop applications you download from
                    this Website, which provide dedicated non-browser-based
                    interaction between you and this Website. <br />
                    When you interact with our advertising and applications on
                    third-party websites and services if those applications or
                    advertising include links to this policy. <br />
                    <br />
                    The Website may include links to third-party websites,
                    plug-ins, services, social networks, or applications.
                    Clicking on those links or enabling those connections may
                    allow the third party to collect or share data about you. If
                    you follow a link to a third-party website or engage a
                    third-party plugin, please note that these third parties
                    have their own privacy policies and we do not accept any
                    responsibility or liability for these policies. We do not
                    control these third-party websites, and we encourage you to
                    read the privacy policy of every website you visit. <br />
                    This policy DOES NOT apply to information that: <br />
                    We collect offline or through any other means, including on
                    any other Company or third-party website (including our
                    affiliates and subsidiaries). <br />
                    You provide to or is collected by any third party (including
                    our affiliates and subsidiaries), through any application or
                    content (including advertising) that may link to or be
                    accessible from or on the Website. <br />
                    <br />
                    Please read this policy carefully to understand our policies
                    and practices for collecting, processing, and storing your
                    information. If you do not agree with our policies and
                    practices, your choice is not to use our Website. By
                    accessing or using this Website, you indicate that you
                    understand, accept, and consent to the practices described
                    in this policy. This policy may change from time to time
                    (see Changes to Our Privacy Policy). Your continued use of
                    this Website after we make changes indicates that you accept
                    and consent to those changes, so please check the policy
                    periodically for updates.{" "}
                  </>
                </Text>
              </div>
              <div className="flex flex-col items-start gap-1">
                <Heading className="mb-2" as="h5">
                  INFORMATION WE COLLECT ABOUT YOU{" "}
                </Heading>
                <Text as="p" className="w-full leading-[27px]">
                  <span className="text-greyscale-grey">
                    We collect and use several types of information from and
                    about you, including: <br />
                  </span>
                  <span className="font-bold text-white-0">
                    Personal information,&nbsp;
                  </span>
                  <span className="text-greyscale-grey">
                    that we can reasonably use to directly or indirectly
                    identify you, such as your name, mailing address, e-mail
                    address, telephone number, Internet protocol (IP) address
                    used to connect your computer to the Internet, user name or
                    other similar identifier, and any other identifier we may
                    use to contact you (&quot;personal information&quot;).{" "}
                    <br />
                    We provide an opportunity for any user to unsubscribe from
                    our email list or opt-out of contact on an ongoing basis by
                    accessing our Website, or using the unsubscribe mechanism at
                    the bottom of our e-mails. <br />
                    <br />
                  </span>
                  <span className="font-bold text-white-0">
                    Non-personal information,
                  </span>
                  <span className="text-white-0">&nbsp;</span>
                  <span className="text-greyscale-grey">
                    that does not directly or indirectly reveal your identity or
                    directly relate to an identifiable individual, such as
                    demographic information, or statistical or aggregated
                    information. Statistical or aggregated data does not
                    directly identify a specific person, but we may derive
                    non-personal statistical or aggregated data from personal
                    information. For example, we may aggregate personal
                    information to calculate the percentage of users accessing a
                    specific Website feature. <br />
                    <br />
                  </span>
                  <span className="font-bold text-white-0">
                    Technical information,
                  </span>
                  <span className="font-bold text-greyscale-grey">&nbsp;</span>
                  <span className="text-greyscale-grey">
                    including your login information, browser type and version,
                    time zone setting, browser plug-in types and versions,
                    operating system and platform, or information about your
                    internet connection, the equipment you use to access our
                    Website, and usage details. <br />
                    <br />
                  </span>
                  <span className="font-bold text-white-0">
                    Non-personal details about your Website interactions
                  </span>
                  <span className="text-white-0">
                    , including the full Uniform Resource Locators (URLs),
                    clickstream to, through and from our Website (including date
                    and time), products you viewed or searched for, page
                    response times, download errors, length of visits to certain
                    pages, page interaction information (such as scrolling,
                    clicks, and mouse-overs), methods used to browse away from
                    the page, or any phone number used to call our customer
                    service number.&nbsp;
                  </span>
                </Text>
              </div>
              <div className="flex flex-col items-start gap-1">
                <Heading className="mb-2" as="h6">
                  HOW WE COLLECT INFORMATION ABOUT YOU{" "}
                </Heading>
                <Text as="p" className="w-full leading-[27px]">
                  <span className="text-greyscale-grey">
                    We use different methods to collect your information,
                    including through: <br />
                    Direct interactions with you when you provide it to us, for
                    example, by filling in forms or corresponding with us by
                    phone, email, or otherwise. <br />
                    Automated technologies or interactions, as you navigate
                    through our Website. Information collected automatically may
                    include usage details, IP addresses, and information
                    collected through cookies, and other tracking technologies.{" "}
                    <br />
                    Third parties or publicly available sources, for example,
                    our business partners. <br />
                    <br />
                  </span>
                  <span className="font-bold text-greyscale-grey">
                    Information You Provide to Us
                  </span>
                  <span className="text-greyscale-grey">
                    &nbsp; <br />
                    The information we collect directly from you on or through
                    our Website may include: <br />
                    Information that you provide by filling in forms on our
                    Website. This includes information provided at the time of
                    subscribing to our service. We may also ask you for
                    information when you enter a contest or promotion sponsored
                    by us, and when you report a problem with our Website.{" "}
                    <br />
                    Records and copies of your correspondence (including email
                    addresses), if you contact us. <br />
                    Your responses to surveys that we might ask you to complete
                    for research purposes. <br />
                    Your search queries on the Website. <br />
                    <br />
                  </span>
                  <span className="font-bold text-greyscale-grey">
                    Information We Collect Through Cookies and Other Automatic
                    Data Collection Technologies <br />
                  </span>
                  <span className="text-greyscale-grey">
                    As you navigate through and interact with our Website, we
                    may use cookies or other automatic data collection
                    technologies to collect certain information about your
                    equipment, browsing actions, and patterns, including: <br />
                    Details of your visits to our Website, including traffic
                    data, location data, logs, and other communication data and
                    the resources that you access and use on the Website. <br />
                    Information about your computer and internet connection,
                    including your IP address, operating system, and browser
                    type. <br />
                    <br />
                    We may also use these technologies to collect information
                    about your online activities over time and across
                    third-party websites or other online services (behavioral
                    tracking). To learn more or to opt-out of tailored
                    advertising please visit Digital Advertising Alliance of
                    Canada Opt-Out Tool for information on how you can opt out
                    of behavioral tracking on this Website and how we respond to
                    web browser signals and other mechanisms that enable
                    consumers to exercise choice about behavioral tracking.{" "}
                    <br />
                    <br />
                    The information we collect automatically is statistical data
                    and does not include personal information, and we may
                    maintain it or associate it with personal information we
                    collect in other ways, that you provide to us, or receive
                    from third parties. It helps us to improve our Website and
                    to deliver a better and more personalized service, including
                    by enabling us to: <br />
                    <br />
                    Estimate our audience size and usage patterns. <br />
                    Store information about your preferences, allowing us to
                    customize our Website according to your individual
                    interests. <br />
                    Speed up your searches. <br />
                    Recognize you when you return to our Website. <br />
                    <br />
                    The technologies we use for this automatic data collection
                    may include: <br />
                    <br />
                  </span>
                  <span className="font-bold text-white-0">Cookies</span>
                  <span className="text-greyscale-grey">
                    &nbsp;(or browser cookies). A cookie is a small file placed
                    on the hard drive of your computer. You may refuse to accept
                    browser cookies by activating the appropriate setting on
                    your browser. However, if you select this setting you may be
                    unable to access certain parts of our Website. Unless you
                    have adjusted your browser setting so that it will refuse
                    cookies, our system will issue cookies when you direct your
                    browser to our Website. <br />
                  </span>
                  <span className="font-bold text-white-0">Flash Cookies</span>
                  <span className="text-greyscale-grey">
                    . Certain features of our Website may use local stored
                    objects (or Flash cookies) to collect and store information
                    about your preferences and navigation to, from, and on our
                    Website. Flash cookies are not managed by the same browser
                    settings that are used for browser cookies. For information
                    about managing your privacy and security settings for Flash
                    cookies, see Choices About How We Use and Disclose Your
                    Information. <br />
                  </span>
                  <span className="font-bold text-white-0">
                    Web Beacons. Pages of our Website may contain small
                    electronic files known as web beacons (also referred to as
                    clear gifs, pixel tags, and single-pixel gifs) that permit
                    the Company, for example, to count users who have visited
                    those pages and for other related website statistics (for
                    example, recording the popularity of certain website content
                    and verifying system and server integrity). &nbsp;
                  </span>
                </Text>
              </div>
              <div className="flex flex-col items-start gap-1">
                <Heading className="mb-2" as="h4">
                  HOW WE USE YOUR INFORMATION{" "}
                </Heading>
                <Text as="p" className="w-full leading-[27px]">
                  We use information that we collect about you or that you
                  provide to us, including any personal information: <br />
                  To present our Website and its contents to you. <br />
                  To provide you with information, products, or services that
                  you request from us. <br />
                  To fulfill the purposes for which you provided the information
                  or that were described when it was collected, or any other
                  purpose for which you provide it. <br />
                  To carry out our obligations and enforce our rights arising
                  from any contracts with you, including for billing and
                  collection or to comply with legal requirements. <br />
                  To notify you about changes to our Website or any products or
                  services we offer or provide though it. <br />
                  To improve our Website, products or services, marketing, or
                  customer relationships and experiences. <br />
                  To allow you to participate in interactive features, social
                  media, or similar features on our Website. <br />
                  To measure or understand the effectiveness of the advertising
                  we serve to you and others, and to deliver relevant
                  advertising to you. <br />
                  In any other way we may describe when you provide the
                  information. <br />
                  For any other purpose with your consent.{" "}
                </Text>
              </div>
              <div className="flex flex-col items-start gap-1">
                <Heading className="mb-2" as="h4">
                  DISCLOSURE OF YOUR INFORMATION{" "}
                </Heading>
                <Text as="p" className="w-full leading-[27px]">
                  <span>
                    We may disclose aggregated information about our users, and
                    information that does not identify any individual, without
                    restriction. <br />
                    <br />
                    We may disclose personal information that we collect or you
                    provide as described in this privacy policy: <br />
                    To our subsidiaries and affiliates.
                    <br />
                    In accordance with applicable law, to a buyer or other
                    successor in the event of a merger, divestiture,
                    restructuring, reorganization, dissolution, or other sale or
                    transfer of some or all of Pagent Social Corp&#39;s assets,
                    whether as a going concern or as part of bankruptcy,
                    liquidation, or similar proceeding, in which personal
                    information held by Pagent Social Corp. about our customers
                    and users is among the assets transferred.
                    <br />
                    To advertisers and advertising networks that require the
                    information to select and serve relevant advertisements to
                    you and others. We do not disclose data about identifiable
                    individuals to our advertisers, but we may provide them with
                    aggregate information about our users (for example, we may
                    inform them that 400 women between 30 and 45 have clicked on
                    their advertisement on a specific day). We may also use such
                    aggregate information to help advertisers target a specific
                    audience (for example, men in a specific location). We may
                    make use of the personal information we have collected from
                    you to enable us to display our advertisers&#39;
                    advertisement to that target audience.
                    <br />
                    To third parties to market their products or services to you
                    if you have not opted out of these disclosures. For more
                    information, see&nbsp;
                  </span>
                  {/* eslint-disable-next-line no-script-url */}
                  <a
                    style={{ cursor: "text" }}
                    href="javascript:void(0)"
                    className="inline underline"
                  >
                    <>
                      Choices About How We Use and Disclose Your Information.
                      <br />
                      To contractors, service providers, and other third parties
                      we use to support our business (such as analytics and
                      search engine providers that assist us with Website
                      improvement and optimization) and who are contractually
                      obligated to keep personal information confidential, use
                      it only for the purposes for which we disclose it to them,
                      and to process the personal information with the same
                      standards set out in this policy.
                      <br />
                      For any other purpose disclosed by us when you provide the
                      information.
                      <br />
                      With your consent.
                      <br />
                      <br />
                      We may also disclose your personal information:
                      <br />
                      To comply with any court order, law, or legal process,
                      including to respond to any government or regulatory
                      request, in accordance with applicable law.
                      <br />
                      To enforce or apply our terms of use and other agreements,
                      including for billing and collection purposes.
                      <br />
                      If we believe disclosure is necessary or appropriate to
                      protect the rights, property, or safety of Pagent Social
                      Corp., our customers, or others. This includes exchanging
                      information with other companies and organizations for the
                      purposes of fraud protection and credit risk reduction.
                    </>
                  </a>
                </Text>
              </div>
              <div className="flex flex-col gap-5">
                <div className="flex flex-col items-start gap-1">
                  <Heading className="mb-2" as="h4">
                    TRANSFERRING YOUR PERSONAL INFORMATION{" "}
                  </Heading>
                  <Text as="p" className="w-full leading-[27px]">
                    We may transfer personal information that we collect or that
                    you provide as described in this policy to contractors,
                    service providers, and other third parties we use to support
                    our business (such as analytics and search engine providers
                    that assist us with Website improvement and optimization)
                    and who are contractually obligated to keep personal
                    information confidential, use it only for the purposes for
                    which we disclose it to them, and to process the personal
                    information with the same standards set out in this policy.{" "}
                    <br />
                    <br />
                    We may process, store, and transfer your personal
                    information in and to a foreign country, with different
                    privacy laws that may or may not be as comprehensive as
                    Canadian law. In these circumstances, the governments,
                    courts, law enforcement, or regulatory agencies of that
                    country may be able to obtain access to your personal
                    information through the laws of the foreign country.
                    Whenever we engage a service provider, we require that its
                    privacy and security standards adhere to this policy and
                    applicable Canadian privacy legislation. <br />
                    <br />
                    By submitting your personal information or engaging with the
                    Website, you consent to this transfer, storage, or
                    processing.{" "}
                  </Text>
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col items-start gap-1">
                    <Heading className="mb-2" as="h4">
                      CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION{" "}
                    </Heading>
                    <Text as="p" className="w-full leading-[27px]">
                      <span>
                        We strive to provide you with choices regarding the
                        personal information you provide to us. We have created
                        mechanisms to provide you with the following control
                        over your information: <br />
                        Tracking Technologies and Advertising. You can set your
                        browser to refuse all or some browser cookies, or to
                        alert you when cookies are being sent. To learn how you
                        can manage your Flash cookie settings, visit the Flash
                        player settings page on Adobe&#39;s website. If you
                        disable or refuse cookies, please note that some parts
                        of this Website may not be accessible or may not
                        function properly. For more information about tracking
                        technologies, see&nbsp;
                      </span>
                      {/* eslint-disable-next-line no-script-url */}
                      <a
                        style={{ cursor: "text" }}
                        href="javascript:void(0)"
                        className="inline underline"
                      >
                        Information We Collect Through Cookies and Other
                        Automatic Data Collection Technologies.
                        <br />
                        We do not control third parties&#39; collection or use
                        of your information to serve interest-based advertising.
                        However, these third parties may provide you with ways
                        to choose not to have your information collected or used
                        in this way. You can opt out of several third party ad
                        servers&#39; and networks&#39; cookies simultaneously by
                        using an opt-out tool created by the Digital Advertising
                        Alliance of Canada or an opt-out tool created by the
                        Network Advertising Initiative. You can also access
                        these websites to learn more about online behavioural
                        advertising and how to stop websites from placing
                        cookies on your device. Opting out of a network does not
                        mean you will no longer receive online advertising. It
                        does mean that the network from which you opted out will
                        no longer deliver ads tailored to your web preferences
                        and usage patterns.
                      </a>
                    </Text>
                  </div>
                  <div className="flex flex-col items-start gap-1">
                    <Heading className="mb-2" as="h4">
                      DATA SECURITY{" "}
                    </Heading>
                    <Text as="p" className="w-full leading-[27px]">
                      The security of your personal information is very
                      important to us. We use physical, electronic, and
                      administrative measures designed to secure your personal
                      information from accidental loss and from unauthorized
                      access, use, alteration, and disclosure. <br />
                      <br />
                      The safety and security of your information also depends
                      on you. Where we have given you (or where you have chosen)
                      a password for access to certain parts of our Website, you
                      are responsible for keeping this password confidential. We
                      ask you not to share your password with anyone. <br />
                      <br />
                      Unfortunately, the transmission of information via the
                      Internet is not completely secure. Although we do our best
                      to protect your personal information, we cannot guarantee
                      the security of your personal information transmitted to
                      our Website. Any transmission of personal information is
                      at your own risk. We are not responsible for circumvention
                      of any privacy settings or security measures contained on
                      the Website.{" "}
                    </Text>
                  </div>
                  <div className="flex flex-col items-start gap-1">
                    <Heading className="mb-2" as="h4">
                      DATA RETENTION{" "}
                    </Heading>
                    <Text as="p" className="w-full leading-[27px]">
                      Except as otherwise permitted or required by applicable
                      law or regulation, we will only retain your personal
                      information for as long as necessary to fulfill the
                      purposes we collected it for, including for the purposes
                      of satisfying any legal, accounting, or reporting
                      requirements. Under some circumstances we may anonymize
                      your personal information so that it can no longer be
                      associated with you. We reserve the right to use such
                      anonymous and de-identified data for any legitimate
                      business purpose without further notice to you or your
                      consent.{" "}
                    </Text>
                  </div>
                  <div className="flex flex-col items-start gap-1">
                    <Heading className="mb-2" as="h4">
                      ACCESSING AND CORRECTING YOUR PERSONAL INFORMATION
                    </Heading>
                    <Text as="p" className="w-full leading-[27px]">
                      <>
                        It is important that the personal information we hold
                        about you is accurate and current. Please keep us
                        informed if your personal information changes. By law
                        you have the right to request access to and to correct
                        the personal information that we hold about you. <br />
                        <br />
                        If you want to review, verify, correct, or withdraw
                        consent to the use of your personal information you may
                        also send us an email using the “Contact Us” feature to
                        request access to, correct, or delete any personal
                        information that you have provided to us. We may not
                        accommodate a request to change information if we
                        believe the change would violate any law or legal
                        requirement or cause the information to be incorrect.{" "}
                        <br />
                        <br />
                        We may request specific information from you to help us
                        confirm your identity and your right to access, and to
                        provide you with the personal information that we hold
                        about you or make your requested changes. Applicable law
                        may allow or require us to refuse to provide you with
                        access to some or all of the personal information that
                        we hold about you, or we may have destroyed, erased, or
                        made your personal information anonymous in accordance
                        with our record retention obligations and practices. If
                        we cannot provide you with access to your personal
                        information, we will inform you of the reasons why,
                        subject to any legal or regulatory restrictions.{" "}
                      </>
                    </Text>
                  </div>
                  <div className="flex flex-col items-start gap-1">
                    <Heading className="mb-2" as="h4">
                      WITHDRAWING YOUR CONSENT{" "}
                    </Heading>
                    <Text as="p" className="w-full leading-[27px]">
                      Where you have provided your consent to the collection,
                      use, and transfer of your personal information, you may
                      have the legal right to withdraw your consent under
                      certain circumstances. To withdraw your consent, if
                      applicable, contact us at the “Contact Us” page. Please
                      note that if you withdraw your consent we may not be able
                      to provide you with a particular product or service. We
                      will explain the impact to you at the time to help you
                      with your decision.{" "}
                    </Text>
                  </div>
                  <div className="flex flex-col items-start gap-1">
                    <Heading as="h4">CHANGES TO OUR PRIVACY POLICY </Heading>
                    <Text as="p" className="w-full leading-[27px]">
                      <>
                        It is our policy to post any changes we make to our
                        privacy policy on this page. If we make material changes
                        to how we treat our users&#39; personal information, we
                        will notify you through a notice on the Website home
                        page. <br />
                        <br />
                        We include the date the privacy policy was last revised
                        at the top of the page. You are responsible for ensuring
                        we have an up-to-date, active, and deliverable email
                        address for you, and for periodically visiting our
                        Website and this privacy policy to check for any
                        changes.{" "}
                      </>
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
