import React from "react";

const sizes = {
  "16_medium": "font-plusjakartasans text-[16px] font-medium",
  textxs: "text-[14px] font-medium",
  textlg: "text-[24px] font-medium md:text-[22px]",
  textxl: "text-[85px] font-medium md:text-[48px]",
  text2xl: "text-[106px] font-medium md:text-[48px]",
  headingxs: "text-[16px] font-bold",
  headings: "text-[18px] font-bold",
  headingmd: "text-[20px] font-bold",
  headinglg: "text-[24px] font-semibold md:text-[22px]",
  headingxl: "text-[28px] font-bold md:text-[26px] sm:text-[24px]",
  heading2xl: "text-[44px] font-bold md:text-[40px] sm:text-[34px]",
  heading3xl: "text-[46px] font-bold md:text-[42px] sm:text-[36px]",
  heading4xl: "text-[56px] font-semibold md:text-[48px] sm:text-[42px]",
};

const Heading = ({ children, className = "", size = "headinglg", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-white-0 font-avenirnext ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
