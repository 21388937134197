import { Img } from "components";

const Aboutus = () => {
  return (
    <div
      className="banner-sectionmain w-full bg-[#090909] pt-[30px] pb-[60px] sm:pb-[75px] md:pb-[90px] lg:pb-[130px] xl:pb-[130px] relative overflow-hidden"
      id="about"
    >
      <Img
        src="/images/img_group_1561530280.png"
        className="absolute right-[5.5rem] top-[2.5rem] w-6"
      />
      <Img
        src="/images/img_vector_679.png"
        className="absolute right-[10rem] top-[6rem] w-[5rem]"
      />
      <Img
        src="/images/Ellipse 23403.png"
        className="absolute right-0 -top-[30rem]"
      />
      <Img
        src="/images/yellow-vector.png"
        className="absolute right-[15px] sm:right-[37px] md:right-[37px] lg:right-[37px] bottom-2 w-8 xs:w-12 md:w-18 lg:w-24"
      />

      <div className="relative container lg:w-10/12 xl:w-10/12 md:w-11/12 px-[15px] sm:px-0 md:px-0 lg:px-0 mx-auto lg:grid xl:grid md:flex lg:grid-cols-2 xl:grid-cols-2 lg:flex-col xl:flex-col md:flex-col gap-8 md:gap-12 min-h-full">
        {/* Desktop image */}
        <div className="hidden md:hidden lg:flex xl:flex items-center justify-center">
          <Img src="/images/about us v2.png" className="max-w-full" />
        </div>

        {/* Mobile image */}
        <div className="md:hidden flex justify-center mb-8">
          <Img src="/images/about us v2.png" className="w-full max-w-lg" />
        </div>

        {/* Desktop content */}
        <div className="hidden md:flex flex-col justify-center items-start gap-y-4">
          <div className="bg-[#59595a] px-7 py-3 font-semibold text-xl w-max text-white-0 rounded-[25px] flex justify-center items-center gap-x-2 mt-20">
            <div className="w-2 h-2 bg-[#ffa801] rounded-full"></div>
            <h2>About the App</h2>
          </div>

          <div style={{ textAlign: "left" }}>
            <h3 className="text-[20px] sm:text-[24px] md:text-[30px] lg:text-[38px] xl:text-[40px] font-bold text-[#fff] mb-[20px]">
              Play against similarly skilled users in challenges to earn badges
              and rise to the top of the leaderboard.
              {/*<span className="text-gradient-color"> Pagent</span>*/}
            </h3>
            {/*<p*/}
            {/*  className="text-[16px] sm:text-[18px] md:text-[18px] lg:text-[20px] xl:text-[20px] font-[500] text-left leading-[38px] mb-[20px]">*/}
            {/*  EMBARK ON A JOURNEY OF DISCOVERY AND EXPRESSION IN A PLATFORM DESIGNED FOR SHOWCASING YOUR UNIQUE TALENTS.*/}
            {/*</p>*/}

            <p className="text-[16px] sm:text-[18px] md:text-[18px] lg:text-[20px] xl:text-[20px] font-[400] text-left text-[#b9b9b8] leading-[26px] sm:leading-[28px] md:leading-[28px] lg:leading-[38px] xl:leading-[38px]">
              Join a social gaming app that connects you with new people and
              lets you share your skill and creativity. Compete in live
              challenges to showcase your talent in your chosen category and
              rise to the top of the leaderboard.
            </p>
          </div>
          <button
            style={{
              backgroundColor: "#51555C",
              width: "228px",
              height: "60px",
            }}
            className="mt-8 px-7 py-3 text-white-0 bg-[#31343a] rounded-full text-xl"
          >
            Coming Soon
          </button>
        </div>
        {/* iPad image */}
        <div className="hidden md:flex lg:hidden xl:hidden items-center justify-center">
          <Img src="/images/about-us-img.png" className="max-w-[400px]" />
        </div>
        {/* Mobile content */}
        <div className="md:hidden flex flex-col items-center gap-y-4">
          <div className="bg-[#59595a] px-7 py-3 font-semibold text-xl w-max text-white-0 rounded-[25px] flex justify-center items-center gap-x-2 -mt-30 -ml-44">
            <div className="w-2 h-2 bg-[#ffa801] rounded-full"></div>
            <h2>About Us</h2>
          </div>

          <div style={{ textAlign: "left" }}>
            <h3 className="text-[20px] sm:text-[24px] md:text-[30px] lg:text-[40px] xl:text-[40px] font-bold text-[#fff] mb-[20px]">
              Compete against other users in challenges, earn badges, and climb
              the leaderboard on
              <span className="text-gradient-color"> Pagent</span>
            </h3>

            <p className="text-[16px] sm:text-[18px] md:text-[18px] lg:text-[20px] xl:text-[20px] font-[400] text-left text-[#b9b9b8] leading-[26px] sm:leading-[28px] md:leading-[28px] lg:leading-[38px] xl:leading-[38px]">
              Join a social media app that connects you with new people and lets
              you share and explore creativity. Win live challenges, earn
              badges, and climb the leaderboard. Choose your category and
              showcase your talents in a vibrant community.
            </p>
            <button className="mt-8 px-7 py-3 text-white-0 bg-[#31343a] rounded-3xl text-xl">
              Coming Soon
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
