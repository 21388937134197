import React from "react";
import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./ScrollTop"; 
import "./assets/css/responsive.css"

function App() {
  return (
    <Router>
      <ScrollToTop />     
        <Routes />      
    </Router>
  );
}

export default App;
